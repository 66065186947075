<template>
  <div>
    <v-toolbar flat>
      <v-icon size="100">
        fas fa-chart-line
      </v-icon>
      <v-toolbar-title class="pl-4" style="font-size: 24px; text-transform:capitalize">
        Dashboard - Integração
      </v-toolbar-title>
      <!-- <v-btn @click="carregarDados()">chamar funcao</v-btn> -->
      <v-spacer></v-spacer>
      <v-toolbar-title class="pl-4" style="font-size: 24px">
       Última atualização: {{ ultimaAtt }}
      </v-toolbar-title>
      <v-app-bar-nav-icon @click="fechar">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>
    <!-- Inicio Integracao  mes corrente-->
    <v-container fluid>
      <div>
        <v-col cols="3">
          <v-row>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  :value="formatDate"
                  label="Período"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :show-current="false"
                v-model="date"
                type="month"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date);loadByYearMonth();"
                >
                OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-row>
        </v-col>
        <v-card hide-default-footer>
          <template>
            <v-divider></v-divider>
            <v-card-title
              class="subheading font-weight-bold "
              style="background-color: rgb(3, 56, 137); color: #FFF; display: flex; justify-content: center; text-transform:capitalize"
            >
              Integração {{ currentDateStr }}
            </v-card-title>
            <v-divider></v-divider>
            <v-row class="alinhamentoCardsOperacoes">
              <v-card style="background-color: #0E3877; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"
                >
                  Integrado
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.totalIntegrados }} </strong>
                </v-card-text>
              </v-card>
              <v-card style="background-color: #0E3877; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"
                >
                  Falha
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.falha }} </strong>
                </v-card-text>
              </v-card>
              <v-card style="background-color: #0E3877; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"
                >
                  Descartado
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.descartados }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #29A40A; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Concluído
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.concluido }} </strong>
                </v-card-text>
              </v-card>
            </v-row>
          </template>
        </v-card>
      </div>
    </v-container>
    <!-- Fim Integracao  mes corrente-->

    <!-- Inicio Integracao periodo total-->
    <v-container fluid>
      <div>
        <v-card hide-default-footer>
          <template>
            <v-divider></v-divider>
            <v-card-title
              class="subheading font-weight-bold "
              style="background-color: rgb(3, 56, 137); color: #FFF; display: flex; justify-content: center;"
            >
              Integrações não concluídas
            </v-card-title>
            <v-divider></v-divider>
            <v-row class="alinhamentoCardsOperacoes">
              <v-card style="background-color: #0E3877; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"
                >
                  Pedido
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.pedidos }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #7D7E81; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Embalamento
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.embalamentos }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #AC9C0E; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Faturamento
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.faturamentos }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #E18633; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Conferência
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.conferencia }} </strong>
                </v-card-text>
              </v-card>
            </v-row>
          </template>
        </v-card>
      </div>
    </v-container>
    <!-- Fim Integracao periodo total-->

    <!-- Inicio Rastreio -->

    <v-container fluid>
      <div>
        <v-card hide-default-footer>
          <template>
            <v-divider></v-divider>
            <v-card-title
              class="text-center subheading font-weight-bold "
              style="background-color: rgb(3, 56, 137); color: #FFF; display: flex; justify-content: center;"
            >
              Rastreios dos últimos 4 meses
            </v-card-title>
            <v-divider></v-divider>
            <v-row class="alinhamentoCards">
              <v-card style="background-color: #83878C; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Sem rastreio
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.semRastreio }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: rgb(170, 170, 170); color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Não postado
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.naoPostado }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #0E980B; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Em transito
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.transito }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #000000; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Entregue
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.entregue }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #D00A0A; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Em atraso
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.atraso }} </strong>
                </v-card-text>
              </v-card>

              <v-card style="background-color: #CFB116; color: white" class="styleCards">
                <v-card-title
                  class="subheading font-weight-bold styleTittle"

                >
                  Alerta
                </v-card-title>
                <v-card-text style="color: white;" class="styleContent">
                  <strong> {{ items.alerta }} </strong>
                </v-card-text>
              </v-card>
            </v-row>
          </template>
        </v-card>
      </div>
    </v-container>

    <!-- Fim Rastreio -->

    <!-- Inicio Meses Anteriores-->
    <!--<v-container fluid>
      <div>
        <v-card hide-default-footer>
          <template>
            <v-divider></v-divider>
            <v-card-title
              class="subheading font-weight-bold "
              style="background-color: rgb(3, 56, 137); color: #FFF; display: flex; justify-content: center;"
            >
              Integração Meses Anteriores
            </v-card-title>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" class="mt-4 px-6">
                <v-data-table
                  dense
                  :headers="grid.cabecalho"
                  :items="grid.items"
                  :sort-by="['ano', 'mes']"
                  :sort-desc="[true, true]"
                  hide-default-footer
                  class="elevation-1"
                  :loading="grid.carregando"
                  disable-pagination
                >
                </v-data-table>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </div>
    </v-container>-->
    <!-- Fim Meses Anteriores -->

    <!-- Inicio Loader  -->

    <v-dialog
      v-model="loading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Carregando dados...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Fim Loader  -->
  </div>
</template>

<script>
import { baseApiUrlPersys } from "@/global";
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    codigo_empresa: '',
    items: [],
    carregando: false,
    temporizador: 60,
    countdown: null,
    ultimaAtt: null,
    loading: false,
    currentDateStr: '',
    date: new Date().toISOString().substr(0, 7),
    menu: false,

    grid: {
      cabecalho: [
        { text: "Ano", value: "ano", sortable: false },
        { text: "Mês", value: "mes", sortable: false },
        { text: "Total pedidos recebidos", value: "numeroTotalPedidos", sortable: false },
        { text: "Pedidos completos", value: "numeroPedidos", sortable: false },
        { text: "Cotações com sucessos", value: "numeroCotacaoSucesso", sortable: false },
        { text: "Cotações com falha", value: "numeroCotacaoFalha", sortable: false },
        { text: "Embalamentos", value: "numeroCotacaoEmbalamento", sortable: false },
        { text: "Pedidos", value: "numeroObjeto", sortable: false },
      ],
      items: [],
      carregando: false,
    },
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    this.loading = true;
    // await this.carregarMesesAnteriores();
    await this.loadByYearMonth();
    // await this.carregarDados();
    await this.countDownTimer();
    this.date = new Date().toISOString().substr(0, 7);
  },

  beforeDestroy() {
    clearInterval(this.countdown);
  },

  methods: {
    getData(){
      this.ultimaAtt = (moment(new Date()).format("DD/MM/YYYY HH:mm:ss"))
    },

    async carregarMesesAnteriores(){
      this.grid.carregando = true;
      await axios
        .get(
          `${baseApiUrlPersys}/indicador`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          this.grid.carregando = false;
          this.grid.items = res.data;
          // console.log(this.grid.items)
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
    },

    async countDownTimer () {
      if (this.temporizador > 0) {
        this.countdown =
        setTimeout(() => {
          this.countDownTimer()
          this.temporizador -= 1
        }, 1000)
      }else if(this.temporizador == 0) {
        this.temporizador = 61
        // await this.carregarDados()
        // await this.carregarMesesAnteriores();
        await this.loadByYearMonth();
        this.countDownTimer()
      }
    },

    fechar() {
      this.$router.push("/");
    },

    async carregarDados() {
      await axios
        .get(
          `${baseApiUrlPersys}/order/indicador/month`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          this.items = res.data;
          this.getData();
          // console.log(this.items)
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    async loadByYearMonth() {
      this.loading = true;
      let arr = this.date.split('-');
      await axios
        .get(
          `${baseApiUrlPersys}/order/indicador/month/${arr[1]}/year/${arr[0]}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          this.items = res.data;
          this.getData();
          // console.log(this.items)
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(()=>{
          this.loading = false;
        })
    }
  },
  watch: {
    'date': {
      handler: function (val, oldVal) {
        let arr = val.split('-');

        let arrMonths = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro'
        ];

        if(arrMonths[1] < 10) {
          arrMonths[1] = Number(arrMonths[1]);
        }

        this.currentDateStr = arrMonths[arr[1] - 1] + " " + arr[0];
      },
    }
  },
  computed: {
    formatDate() {
      moment.updateLocale('pt', {
        months : [
          "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
          "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
        ]
      });
      return this.date
        ? moment(this.date).format("MMMM/YYYY")
        : "";
    },
  }
};
</script>
<style scoped>

.filtro_data {
  display: flex !important;
  align-items: center !important;
}
.v-text-field__details {
  display: none !important;
}
.alinhamento {
  padding-bottom: 0 !important;
  padding-top: 25px !important;
}

.alinhamentoCards{
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.alinhamentoCardsOperacoes{
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.color-text{
  color: white;
}

.styleCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 120px;
  margin-bottom: 40px;
  border-radius: 10px 10px 0 0 !important;
}

.styleContent{
  display: flex;
  justify-content: center;
  font-size: 30px;
  padding-bottom: 30px !important;
}

.styleTittle{
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500 !important;
  padding-top: 0 !important;
}

</style>